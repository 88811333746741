import {SubmissionError} from 'redux-form';
import {get, post, patch, del} from 'core/configureRequest';
import {omit, includeChanged, convertPhotosToBase64} from 'utils/helpers';
import {notifyError, notifySuccessWithTimeout} from 'features/notification/slice';
import {showModal, hideModal} from 'features/modal/slice';

const requestUser = () => ({
    type: 'REQUEST_USER',
});

export const requestUsers = () => ({
    type: 'REQUEST_USERS',
});

export const receiveUsers = (users) => ({
    type: 'RECEIVE_USERS',
    users,
});

const receiveUsersFailure = () => ({
    type: 'RECEIVE_USERS_FAILURE',
});

export const receiveUser = (user) => ({
    type: 'RECEIVE_USER',
    user,
});

export const requestDeleteUser = () => ({
    type: 'REQUEST_DELETE_USER',
});

export const receiveDeleteUser = (id) => ({
    type: 'RECEIVE_DELETE_USER',
    id,
});

export const setSortKey = (key) => ({
    type: 'SET_USER_VISIBILITY_FILTER',
    key,
});

export const getUser = (userId) => (dispatch, getState) => {
    dispatch(requestUser());

    return get(`/proxy/api/user/${userId}/`)
        .then((data) => {
            dispatch(receiveUser(data.data.user));

            return data;
        })
        .catch((e) => Promise.reject(e));
};

export const getUsers = () => (dispatch, getState) => {
    dispatch(requestUsers());

    return get('/proxy/api/user/')
        .then((data) => {
            const otherUsers = data.data.users.filter(
                (user) => user.user_id !== getState().users.currentUserId,
            );

            dispatch(receiveUsers(otherUsers));

            return data;
        })
        .catch((e) => dispatch(receiveUsersFailure()));
};

//@todo map dispatch to props
export const searchEmails = (email, getState) => {
    return get(`/proxy/api/user/?email=${email}`)
        .then((data) => {
            return data.data.users;
        })
        .catch((e) => {
            return Promise.reject(e);
        });
};

// export const getUsersIfNeeded = () => (dispatch, getState) => {
// 	if (Object.keys(getState().users.entities).length > 10) return;

// 	dispatch(getUsers());
// }

export const getUserIfNeeded = (userId) => (dispatch, getState) => {
    const state = getState();
    if (
        state.users.entities.userId &&
        state.users.entities.userId.response_format !== 'formatSummary'
    ) {
        return Promise.resolve(state.users.entities.userId);
    }

    return dispatch(getUser(userId));
};

export const getEditorialWriters = () => (dispatch, getState) => {
    dispatch(requestUsers());

    return get('/proxy/api/user/search/writers/')
        .then((data) => {
            dispatch(receiveUsers(data.data.users));

            return data;
        })
        .catch((e) => dispatch(receiveUsersFailure()));
};

export const getEditorialEditors = () => (dispatch, getState) => {
    dispatch(requestUsers());

    return get('/proxy/api/user/search/editors/')
        .then((data) => {
            dispatch(receiveUsers(data.data.users));

            return data;
        })
        .catch((e) => dispatch(receiveUsersFailure()));
};

export const createUser = (values) => (dispatch, getState) => {
    return convertPhotosToBase64(values)
        .then((values) => post('/proxy/api/user/', values))
        .then((data) => {
            dispatch(receiveUser(data.data.user));
            dispatch(notifySuccessWithTimeout('Successfully created user!'));

            return Promise.resolve(data);
        })
        .catch((e) => {
            dispatch(notifyError(e && e.message ? e.message : 'Could not create user'));
            return Promise.reject(e);
        });
};

export const createAccountUser = (values) => (dispatch, getState) => {
    return convertPhotosToBase64(values)
        .then((values) => post('/proxy/api/user/', values))
        .then((data) => {
            dispatch(receiveUser(data.data.user));
            dispatch(notifySuccessWithTimeout('Successfully created user!'));

            return Promise.resolve(data);
        })
        .catch((e) => {
            let message =
                'Account created, user not created: ' +
                (e.message ? e.message : 'could not create user');
            return Promise.reject(new SubmissionError({_error: message}));
        });
};

export const editUser = (values) => (dispatch, getState) => {
    return convertPhotosToBase64(values)
        .then((values) => {
            const changedValues = includeChanged(
                values,
                getState().users.entities[values.user_id],
            );
            return patch(
                `/proxy/api/user/${values.user_id}/`,
                omit(changedValues, 'user_id'),
            );
        })
        .then((data) => {
            dispatch(receiveUser(data.data.user));

            return data;
        })
        .catch((e) => Promise.reject(e && e.message ? e.message : 'Could not edit user'));
};

export const deleteUser = (user) => (dispatch, getState) => {
    dispatch(requestDeleteUser());

    const promise = new Promise((resolve, reject) => {
        dispatch(
            showModal({
                type: 'DELETE_USER',
                props: {
                    message: `Are you sure you want to delete ${user.email}?`,
                    submitText: 'Delete',
                    submittingText: 'Deleting...',
                    handleClose: () => dispatch(hideModal()),
                    handleConfirm: () => {
                        return del(`/proxy/api/user/${user.user_id}/`)
                            .then((data) => {
                                dispatch(receiveDeleteUser(data.data.user_id));
                                dispatch(
                                    notifySuccessWithTimeout(
                                        `${user.email} successfully deleted`,
                                    ),
                                );
                                dispatch(hideModal());

                                return resolve(data);
                            })
                            .catch((e) => reject(e));
                    },
                },
            }),
        );
    });

    return promise;
};

export const resetPassword = (email) => (dispatch, getState) => {
    return post(`/proxy/password_update_request/${email}/`);
};
