import React from 'react';

import ExclamationRed from 'assets/img/icons/exclamation-red.svg';
import ExclamationYellow from 'assets/img/icons/exclamation-yellow.svg';
import Checkmark from 'assets/img/icons/checkmark-green.svg';
import * as styles from './styles';
import {NotificationContainer} from './styles';

const Notification = (props) => {
    const {success, error, updateAvailable} = props;

    const active = updateAvailable || error || success;

    return (
        <NotificationContainer>
            <div style={styles.notification(props)}>
                {active && (
                    <div style={styles.iconContainer}>
                        {updateAvailable && <ExclamationYellow />}
                        {success && <Checkmark />}
                        {error && <ExclamationRed />}
                    </div>
                )}
                {active && (
                    <div>
                        {updateAvailable && (
                            <div style={styles.text}>Updates Available!</div>
                        )}
                        {(error || success) && (
                            <div style={styles.text}>{error || success}</div>
                        )}
                        {active && (
                            <div style={styles.dismiss} onClick={props.handleAction}>
                                {updateAvailable ? 'Install' : 'Dismiss'}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </NotificationContainer>
    );
};

export default Notification;
