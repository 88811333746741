import {COLORS} from 'core/styles';
import styled from 'styled-components';

export const KnowledgeBaseContainer = styled.div`
    position: relative;
    display: block;
    height: 50px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    font-size: 12px;
    padding-top: 50px;

    &.active {
        color: ${COLORS.primary};
    }

    &.active * {
        opacity: 1;
        fill: ${COLORS.primary};
    }
`;

export const Title = styled.div`
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const Text = styled.div`
    font-size: 12px;
    display: inline-block;
    font-weight: normal;
    text-transform: none;
    margin-bottom: 10px;
`;

export const Link = styled.a`
    font-size: 12px;
    display: inline-block;
    font-weight: normal;
    text-transform: none;
    margin-bottom: 10px;
    color: #2196f3;
`;
