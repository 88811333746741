export let body = {
    padding: '15px',
    backgroundColor: '#fff',
};

export let icon = {
    width: '24px',
    textAlign: 'center',
    margin: '0 auto 15px',
};

export let title = {
    margin: '0 0 30px',
    padding: '0 20px 5px',
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: '18px',
};

export let buttonContainer = {
    margin: 'auto',
    textAlign: 'center',
};
