import {COLORS, darken} from 'core/styles';
import styled, {css} from 'styled-components';

export let Container = styled.div`
    max-height: 450px;
    overflow-y: scroll;
    padding-top: 15px;
`;

export let Subheader = styled.div`
    text-align: left;
    background-color: ${COLORS.primaryLight};
    padding: 15px;
    text-transform: uppercase;
    color: ${COLORS.primary};
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 15px;
    margin-top: 30px;
`;

export let Section = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 15px;
`;

export let SectionFixed = styled(Section)`
    height: 50px;
`;

export let Example = styled.div`
    flex: 1;
    background-color: #fff;
    text-align: left;
    padding: 15px;

    & blockquote {
        margin-right: 0;
    }

    & p {
        margin: 0;
    }
`;

export let Code = styled.div`
    text-align: left;
    margin: 5px 15px 5px 0;
    padding: 10px 15px;
    background-color: ${COLORS.grayLight};
    flex: 1;
    font-weight: 500;
    color: ${darken(COLORS.grayFont, 10)};
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.1),
        inset -1px -1px 1px rgba(0, 0, 0, 0.1);
`;

export let Quote = styled.blockquote`
    margin-left: 25px;
`;

export let UnorderedList = styled.ul`
    margin: 0;
    padding-left: 25px;
`;

export let OrderedList = styled.ol`
    margin: 0;
    padding-left: 25px;
`;
