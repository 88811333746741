'use client';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import Papa from 'papaparse';

//import socket from 'core/socket';
import AsyncLoad from 'features/generic/components/AsyncLoad/AsyncLoad';
import {isEmpty} from 'utils/helpers';
import {uploadFiles} from 'features/transfer/actions';
import {hideModal} from 'features/modal/slice';
import {searchReviews} from 'features/reviews/actions';
import UploadReviews from 'features/reviews/components/UploadReviews/UploadReviews';

function getSuccessCount(e, key) {
    if (!e || !e.errors || !e.errors[key]) return;

    return e.errors[key].successes + '/' + e.errors[key].rows + ' uploaded. ';
}

function getErrors(e, files) {
    const filenames = files.map((file) => file.name);
    const hasDetailedErrors = e.errors && filenames.includes(Object.keys(e.errors)[0]);

    if (hasDetailedErrors) {
        return Object.keys(e.errors).reduce((acc, key) => {
            if (e.errors[key].errors && e.errors[key].errors.length) {
                acc[key] = e.errors[key].errors.reduce((acc, e) => {
                    const messageReduced = e.message.reduce(
                        (acc, cur) => (acc += ', ' + cur),
                        '',
                    );
                    acc += `(Row ${e.row}) ${messageReduced} `;
                    return acc;
                }, '');
            }
            return acc;
        }, {});
    } else {
        return files.reduce((acc, file) => {
            acc[file.name] = e && e.message ? e.message : 'Could not upload review';
            return acc;
        }, {});
    }
}

let UploadReviewsContainer = (props) => {
    const [state, setState] = useState({
        complete: false,
        status: 'Upload a single or multiple CSV formatted reviews.',
        progress: 0,
        showInfo: false,
        files: [],
        errors: {},
        successCount: {},
    });

    const handleMultipleFiles = (acceptedFiles, rejectedFiles) => {
        setState((state) => {
            return {
                ...state,
                errors: {},
                status: 'Ready for upload.',
                progress: 0,
                complete: false,
                files: acceptedFiles,
            };
        });
    };

    const handleRemoveFile = (filename) => {
        setState((state) => {
            return {
                ...state,
                files: state.files.filter((file) => file.name !== filename),
            };
        });
    };

    const handleUpload = async () => {
        if (isEmpty(state.files) || props.isUploading) return;

        const filesData = await Promise.all(
            state.files.map(
                (file) =>
                    new Promise((resolve, reject) =>
                        Papa.parse(file, {
                            header: true,
                            complete: (result) => {
                                const fileData = {
                                    filename: file.name,
                                    json: result.data,
                                };
                                resolve(fileData);
                            }, // Resolve each promise
                            error: reject,
                        }),
                    ),
            ),
        )
            .then((results) => {
                return results;
            })
            .catch((err) => console.log('Something went wrong:', err));

        const uploadsKeyed = filesData.reduce((acc, upload) => {
            acc[upload.filename] = {reviews: upload.json};
            return acc;
        }, {});
        const path = '/proxy/api/user_review/bulk/';

        props
            .uploadFiles(path, {files: uploadsKeyed})
            .then((data) => {
                setState((state) => {
                    return {
                        ...state,
                        complete: true,
                        status: 'Upload successful!',
                        errors: {},
                    };
                });

                props.searchReviews();
            })
            .catch((e) => {
                setState((state) => ({
                    ...state,
                    complete: true,
                    status: 'Some uploads failed.',
                    errors: getErrors(e, state.files),
                    successCount: state.files.reduce((acc, file) => {
                        const successes = getSuccessCount(e, file.name);
                        if (successes) acc[file.name] = successes;
                        return acc;
                    }, {}),
                }));
            });
    };

    const handleClose = () => {
        if (props.isUploading) {
            props.receiveUploadFailure();
        }

        props.hideModal();
    };

    const toggleInfo = () => {
        setState((state) => {
            return {
                ...state,
                showInfo: !state.showInfo,
            };
        });
    };

    return (
        <UploadReviews
            {...props}
            {...state}
            handleRemoveFile={handleRemoveFile}
            handleClose={handleClose}
            handleUpload={handleUpload}
            toggleInfo={toggleInfo}
            handleMultipleFiles={handleMultipleFiles}
        />
    );
};

UploadReviewsContainer = connect(
    (state) => {
        return {
            isUploading: state.transfer.isUploading,
        };
    },
    {
        hideModal,
        uploadFiles,
        searchReviews,
    },
)(UploadReviewsContainer);

export default AsyncLoad(UploadReviewsContainer, {});
