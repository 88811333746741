import React from 'react';

import LabelStyled from 'features/form/components/Label/LabelStyled';
import {get} from 'utils/helpers';
import {error as errorStyle} from 'features/form/styles';
import {Container, TextAreaStyled} from './styles';

const TextAreaInput = (props) => {
    const error = get(props, 'meta.error');

    let inputProps = {};

    if (props.input) {
        inputProps = props.input;
    }

    if (props.handleRef) {
        inputProps = {...inputProps, ref: props.handleRef};
    }

    if (props.value) {
        inputProps = Object.assign({}, inputProps, {value: props.value});
    } else if (props.defaultValue) {
        inputProps = Object.assign({}, inputProps, {defaultValue: props.defaultValue});
    }

    return (
        <Container $css={props.$css} className={props.className}>
            <LabelStyled
                required={props.required}
                $css={props.labelCss}
                htmlFor={props.name}>
                {props.label || ''}
            </LabelStyled>
            {error && <div style={errorStyle}>{error}</div>}
            <TextAreaStyled
                {...inputProps}
                $css={props.inputCss}
                placeholder={props.placeholder}
                onChange={(e) => {
                    if (props.input && props.input.onChange) props.input.onChange(e);
                    if (props.handleChange) props.handleChange(e);
                }}
            />
        </Container>
    );
};

export default TextAreaInput;
