import React, {useState} from 'react';

import ModalWrapper from 'features/modal/components/ModalWrapper/ModalWrapper';
import CancelButtonStyled from '../../../form/components/CancelButton/CancelButtonStyled';
import SubmitButtonStyled from '../../../form/components/SubmitButtonStyled/SubmitButtonStyled';
import SuccessIcon from 'assets/img/icons/success-mark.svg';
import WarningIcon from 'assets/img/icons/alert.svg';

import * as styles from './styles';

const getIcon = (type) => {
    const upper = type.toUpperCase();
    switch (upper) {
        case 'WARNING':
            return WarningIcon;
        case 'SUCCESS':
            return SuccessIcon;
        default:
            WarningIcon;
    }
};

const Prompt = ({
    message = 'Are you sure?',
    handleConfirm = () => null,
    handleClose = () => null,
    cancelText = 'Cancel',
    isLoading = false,
    loadingText = 'Saving...',
    type = 'warning',
    submitText = 'Confirm',
    children,
    submittingText,
}) => {
    const [state, setState] = useState({
        buttonText: submitText,
    });

    const handleMouseUp = () => {
        setState({
            buttonText: submittingText,
        });

        handleConfirm();
    };

    const Icon = getIcon(type);

    return (
        <ModalWrapper>
            <div style={styles.body}>
                <div style={styles.icon}>
                    <Icon />
                </div>
                <h2 style={styles.title}>{message}</h2>
                {children && <div style={styles.body}>{children}</div>}
                <div style={styles.buttonContainer}>
                    <CancelButtonStyled handleClick={handleClose} />
                    <SubmitButtonStyled
                        submitText={state.buttonText}
                        submittingText={state.buttonText}
                        handleMouseUp={handleMouseUp}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default Prompt;
