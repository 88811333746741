import React from 'react';
import UserDescription from 'features/users/components/UserDescription/UserDescription';
import LogoutIcon from 'assets/img/icons/logout-icon.svg';
import * as styles from './styles';

const UserPanelHeader = (props) => {
    if (!props.user) return <span />;

    return (
        <div style={styles.container}>
            <div style={styles.user}>
                <UserDescription
                    inverted
                    hidePhoto={!props.user.avatar}
                    user={props.user}
                    href={`/users/${props.user.user_id}/edit`}
                />
            </div>
            <div style={styles.logout} onClick={props.handleLogout}>
                <div style={styles.logoutText}>Logout</div>
                <div style={styles.logoutSvgContainer}>
                    <LogoutIcon style={styles.logoutSvg} />
                </div>
            </div>
        </div>
    );
};

export default UserPanelHeader;
