import {COLORS} from 'core/styles';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    position: relative;
    display: block;
    height: 50px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    font-size: 12px;
    padding-top: 17px;

    &.active {
        color: ${COLORS.primary};
    }

    &.active * {
        opacity: 1;
        fill: ${COLORS.primary};
    }
`;

export const Text = styled.div`
    font-size: 12px;
    display: inline-block;
`;

export const StyledArrow = styled.div`
    vertical-align: middle;
    width: 15px;
    height: 15px;
    float: right;
`;

export const StyledSVG = styled.div`
    width: 100%;
    height: 100%;
    opacity: 0.5;
`;
