'use client';

import React, {useState} from 'react';
import {useServerInsertedHTML} from 'next/navigation';
import {ServerStyleSheet, StyleSheetManager} from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

export default function StyledComponentsRegistry({children}) {
    // Only create stylesheet once with lazy initial state
    // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

    useServerInsertedHTML(() => {
        const styles = styledComponentsStyleSheet.getStyleElement();
        styledComponentsStyleSheet.instance.clearTag();
        return <>{styles}</>;
    });

    const shouldForwardProp = (propName, target) => {
        return typeof target === 'string' ? isPropValid(propName) : true;
    };

    if (typeof window !== 'undefined')
        return (
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                {children}
            </StyleSheetManager>
        );

    return (
        <StyleSheetManager
            shouldForwardProp={shouldForwardProp}
            sheet={styledComponentsStyleSheet.instance}>
            {children}
        </StyleSheetManager>
    );
}
