'use client';
import React, {useState} from 'react';
import {connect} from 'react-redux';

import {getListingSubscriptions} from 'features/vip/actions';
import {get, getListingType} from 'utils/helpers';
import {hideModal} from 'features/modal/slice';
import {editListing} from 'features/listings/actions';
import {notifySuccessWithTimeout} from 'features/notification/slice';
import ChangeListingTypeForm from 'features/listings/components/ChangeListingTypeForm/ChangeListingTypeForm';

let ChangeListingTypeFormContainer = (props) => {
    const [state, setState] = useState({
        reason: null,
        error: null,
    });

    const handleReasonUpdate = (e) => {
        setState({
            reason: e.target.value,
        });
    };

    const handleClose = () => props.hideModal();

    const handleSubmit = () => {
        const {listing, toType} = props;
        let req = {
            listing_id: listing.listing_id,
            type: toType,
        };

        if (props.includeReason) {
            req = {
                ...req,
                reason: state.reason,
            };
        }

        props
            .editListing(req)
            .then((data) => {
                if (getListingType(listing) === 'vip' && toType && toType !== 'vip') {
                    props.getListingSubscriptions(listing.listing_id);
                }
                props.notifySuccessWithTimeout(data.message);
                handleClose();
            })
            .catch((e) => {
                setState({
                    error: e && e.message ? e.message : 'Cannot edit listing type',
                });
            });
    };

    return (
        <ChangeListingTypeForm
            {...props}
            {...state}
            handleSubmit={handleSubmit}
            handleReasonUpdate={handleReasonUpdate}
            handleClose={handleClose}
        />
    );
};

ChangeListingTypeFormContainer = connect(
    (state, ownProps) => {
        const fromType =
            get(ownProps.listing, 'type.name') ||
            get(state, 'modal.props.listing.listing_type');
        const toType = ownProps.type;

        return {
            fromType,
            toType,
            isSaving: state.listings.isSaving,
            includeVipWarning: fromType === 'vip' && toType !== 'vip',
            includeReason:
                (fromType === 'vip' ||
                    fromType === 'promoted' ||
                    fromType === 'standard') &&
                (toType === 'claimed' || toType === 'free'),
        };
    },
    {
        editListing,
        hideModal,
        getListingSubscriptions,
        notifySuccessWithTimeout,
    },
)(ChangeListingTypeFormContainer);

export default ChangeListingTypeFormContainer;
