import {useEffect, useState} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {logoutUser} from 'features/auth/actions';
import {useRouter} from 'next/navigation';
import {getIdleTimeoutMicroseconds} from 'utils/auth';
import {connect, useDispatch, useSelector} from 'react-redux';
import {selectCurrentUser} from 'features/users/selectors';
import {refreshToken} from 'features/auth/actions';

const IdleTimer = ({onAction = () => {}}) => {
    const [remaining, setRemaining] = useState(0);
    const router = useRouter();
    const user = useSelector(selectCurrentUser);
    const timeout = getIdleTimeoutMicroseconds(user);
    const dispatch = useDispatch();

    const onIdle = () => {
        dispatch(logoutUser());
        router.push('/login');
    };

    const {getRemainingTime} = useIdleTimer({
        onIdle,
        onAction,
        timeout,
        throttle: 1000,
    });

    // console.log(`Inactive logging out in ${remaining}`);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    return null;
};

export default connect(null, {
    refreshToken,
})(IdleTimer);
