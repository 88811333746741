import {isEmpty} from 'utils/helpers';
import React from 'react';
import {COLORS, darken, lighten, hexToRgb} from 'core/styles';
import styled, {css} from 'styled-components';
const LoadableCsvIcon = React.lazy(() => import('assets/img/icons/csv-icon.svg'));

export const Container = styled.div`
    max-width: 600px;
`;

export const Header = styled.div`
    border-bottom: 1px solid ${darken(COLORS.grayLight, 5)};
    text-align: left;
    padding: 10px 15px 15px;
`;

export const Title = styled.div`
    text-align: left;
    color: ${COLORS.gray};
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 10px;
`;

export const ToggleInfo = styled.div`
    position: relative;
    top: -3px;
    cursor: pointer;
    display: inline-block;
    padding: 5px 10px;
    font-size: 10px;
    border: 1px solid ${darken(COLORS.grayLight, 10)};
    margin-left: 15px;
    text-transform: uppercase;
    color: ${COLORS.grayFont};
    font-weight: 500;

    &:hover {
        border: 1px solid ${COLORS.primary};
        color: ${COLORS.primary};
        text-decoration: underline;
    }

    ${({$showInfo}) =>
        $showInfo &&
        `
      border: 1px solid ${COLORS.primary};
      color: ${COLORS.primary};
    `}
`;

export const Status = styled.div`
    color: ${COLORS.grayFont};
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;

    ${({$errors, $isUploading, $complete}) => {
        const wasSuccessful = isEmpty($errors) && !$isUploading && $complete;

        if ($complete && !wasSuccessful) {
            return `
        color: ${COLORS.danger};
        font-weight: bold;
      `;
        }

        if ($complete && wasSuccessful) {
            return `
        color: ${darken(COLORS.success, 30)};
        font-weight: bold;
      `;
        }
    }}
`;
export const Description = styled.div`
    color: ${COLORS.grayFont};
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
`;

export const Required = styled.span`
    position: relative;
    border: 1px solid ${COLORS.grayFont};
    padding: 2px 5px;
`;

export const Columns = styled.div`
    position: relative;
    display: flex;
    flex-flow: row wrap;
`;

export const Column = styled.div`
    position: relative;
    margin-right: 5px;
    font-size: 12px;
    margin-bottom: 5px;
    padding: 5px 12px;
    border: 1px dashed ${darken(COLORS.grayLight, 20)};
`;

export const RequiredColumn = styled(Column)`
    border: 1px solid ${COLORS.grayFont};
`;

export const BodyContainer = styled.div`
    padding: 25px 10px;
`;

export const Body = styled.div`
    position: relative;
    background-color: ${darken(COLORS.grayLight, 2)};
    border: 1px solid ${darken(COLORS.grayLight, 10)};
`;

export const Target = styled.div`
    overflow-y: scroll;
`;

export const TargetLink = styled.div`
    overflow-y: scroll;
    background-color: rgba(${hexToRgb(COLORS.primary)}, 0.1);
    border: 2px dashed ${COLORS.primary};
    padding: 15px 0;
    ${({$files}) =>
        isEmpty($files) &&
        css`
            padding: 100px 0;
        `}
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 5px 10px;
    background-color: ${({$index}) =>
        $index & 1
            ? `rgba(${hexToRgb(COLORS.grayDark)}, 0.1)`
            : `rgba(${hexToRgb(COLORS.grayMedium)}, 0.1)`};
`;

export const UploadIcon = styled(LoadableCsvIcon)`
    width: 30px;
    margin-right: 15px;
`;

export const Info = styled.div`
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
`;

export const FileName = styled.div`
    font-weight: bold;
    font-size: 11px;
    color: ${darken(COLORS.grayFont, 15)};
    margin-bottom: 5px;
`;

export const ProgressBackground = styled.div`
    position: relative;
    width: 100%;
    height: 15px;
    background-color: ${({$complete}) =>
        $complete
            ? `rgba(${hexToRgb(COLORS.success)}, 0.5)`
            : `rgba(${hexToRgb(COLORS.primary)}, 0.2)`};
    margin-bottom: 5px;
`;

export const Progress = styled.div`
    position: relative;
    width: ${({$complete, $progress}) => ($complete ? '100%' : `${$progress}%`)};
    height: 15px;
    background-color: rgba(${hexToRgb(COLORS.primary)}, 0.2);
    margin-bottom: 5px;
`;

export const SizeProgress = styled.div`
    display: block;
    text-align: left;
`;

export const Size = styled.span`
    color: ${COLORS.grayFont};
    font-weight: bold;
    opacity: 0.6;
    font-size: 11px;
    font-style: italic;
    display: inline;
    margin-right: 5px;
`;

export const ProgressText = styled.div`
    color: ${COLORS.grayFont};
    font-weight: bold;
    opacity: 0.6;
    font-size: 11px;
    font-style: italic;
    display: inline;
    margin-right: 5px;

    ${({$complete, $fileName, $errors}) =>
        $complete && Object.keys($errors).includes($fileName)
            ? `
        color: ${COLORS.danger};
        opacity: 0.65;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 10px;
        font-style: italic;
      `
            : ''}
`;

export const RemoveButton = styled.div`
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
    color: ${COLORS.danger};
    margin: 0 10px;
    width: 45px;
`;

export const Instruction = styled.div`
    color: ${COLORS.grayFont};
    font-size: 10px;
    font-weight: 900;
    text-transform: uppercase;
`;

export const CsvIconStyled = styled(LoadableCsvIcon)`
    margin-bottom: -10px;
    margin-right: 10px;
    height: 40px;
    width: 40px;
`;

export const StyledBrowse = styled.label`
    cursor: pointer;
    color: ${COLORS.primary};
    text-decoration: underline;
    margin-right: 3px;
    margin-left: 3px;
`;

export const Footer = styled.div`
    padding: 0 30px 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const SubmitButton = styled.div`
    cursor: pointer;
    color: #fff;
    font-weight: 900;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    background-color: ${COLORS.primary};
    width: 125px;
    display: inline-block;
    padding-top: 13px;
    height: 40px;
    margin-bottom: 15px;

    &:hover {
        background-color: ${lighten(COLORS.primary, 5)};
    }

    &:active {
        background-color: ${darken(COLORS.primary, 5)};
    }

    ${({$files}) =>
        isEmpty($files) &&
        `
      opacity: 0.5;
    `}
`;

export const StyledLoading = styled.div`
    width: 20px;
    margin-top: -5px;
`;
