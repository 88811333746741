import {COLORS, bp} from 'core/styles';

export let container = {
    backgroundColor: '#fff',
    padding: '30px',
    textAlign: 'center',
};

export let message = {
    margin: '10px auto 15px',
    textAlign: 'center',
    paddingBottom: '15px',
    color: COLORS.grayFont,
    fontWeight: 500,
    borderBottom: `1px solid ${COLORS.grayLight}`,
};

export let input = {
    maxWidth: '200px',
    margin: '0 auto',
};

export let loading = {
    maxWidth: '25px',
};

export let error = {
    fontWeight: 'bold',
    color: COLORS.danger,
    marginBottom: '15px',
};

export let reason = {
    textDecoration: 'underline',
};

export let warning = {
    marginBottom: '15px',
};
