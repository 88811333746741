import React from 'react';
import {KnowledgeBaseContainer, Title, Text, Link} from './styles';

const KnowledgeBase = () => {
    const linkBank = [
        {
            title: 'FAQs for ClientReach',
            href: 'https://rehabs.com/sign-up/frequently-asked-questions/',
        },
        {
            title: 'Updating Listing Information',
            href: 'https://rehabs.com/sign-up/updating-listing-information/',
        },
        {
            title: 'Details for Package Types',
            href: 'https://rehabs.com/sign-up/client-reach-package-details/',
        },
        {
            title: 'Tips & Tricks for Marketing Your Facility',
            href: 'https://rehabs.com/sign-up/how-to-market-a-rehab-center/',
        },
    ];
    return (
        <KnowledgeBaseContainer>
            <Title>KNOWLEDGE BASE</Title>
            <Text>
                Need help with something? Read our guides on how to optimize your account
                and get the most from your listing.
            </Text>
            {linkBank.map((linkItem, index) => (
                <Link
                    key={index}
                    href={linkItem.href}
                    target="_blank"
                    rel="noopener noreferrer">
                    {linkItem.title}
                </Link>
            ))}
        </KnowledgeBaseContainer>
    );
};

export default KnowledgeBase;
