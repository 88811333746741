import {bp, Z, HEADER_HEIGHT, SIDEBAR_WIDTH, COLORS} from 'core/styles';
import styled from 'styled-components';
import {css} from 'styled-components';
import Link from 'next/link';

export let SidebarModalCss = css`
    background-color: ${COLORS.grayDark};
    z-index: ${Z.sidebar};
    position: fixed;
    top: ${HEADER_HEIGHT};
    left: ${(props) => (props.sidebarActive ? '0' : '-75%')};
    bottom: 0;
    width: 75%;
    padding: 15px;
    transition: left 150ms linear;
    ${bp('md')} {
        transition: none;
        width:  ${SIDEBAR_WIDTH};
        left: 0;
        top: 0;
    }
}`;

export let SidebarContainer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background-color: ${COLORS.grayDark};
    overflow: auto;

    ${bp('md')} {
        z-index: ${Z.sidebar};
        width: ${SIDEBAR_WIDTH};
    }
`;

export let SidebarHeaderLink = styled(Link)`
    display: none;
    position: relative;
    z-index: 100;
    color: ${COLORS.grayFont};
    margin: 0;
    padding: 20px 15px;
    background-color: #000;
    font-size: 13px;
    letter-spacing: 2px;
    height: ${HEADER_HEIGHT};
    text-decoration: none;
    text-transform: lowercase;
    font-weight: bold;
    ${bp('md')} {
        display: block;
    }
`;

export const Primary = styled.div`
    background-color: ${COLORS.grayExtraDark};
    padding: 16px 0 25px 0;
`;

// export let primary = {
//     backgroundColor: COLORS.grayExtraDark,
//     padding: '16px 0 25px 0'
// }

export const Secondary = styled.div`
    width: 100%;
    background-color: ${COLORS.grayDark};
    padding: 30px;
    height: auto;
`;

// export let secondary = {
//     width: '100%',
//     backgroundColor: COLORS.grayDark,
//     padding: '30px 30px',
//     height: 'auto',
// };

export const SecondaryTitle = styled.div`
    color: ${COLORS.grayFont};
    text-transform: uppercase;
    font-weight: 900;
    padding-bottom: 10px;
    font-size: 11px;
    letter-spacing: 0.8px;
    margin-bottom: 15px;
`;

// export let secondaryTitle = {
//     color: COLORS.grayFont,
//     textTransform: 'uppercase',
//     fontWeight: 900,
//     paddingBottom: '10px',
//     fontSize: '11px',
//     letterSpacing: '0.8px',
//     marginBottom: '15px',
// };

// export let feedback = {
//     color: COLORS.grayFont,
//     position: 'absolute',
//     bottom: '30px',
//     paddingLeft: '30px',
//     textDecoration: 'underline',
//     fontSize: '12px',
//     fontWeight: 600,
// };

export const Feedback = styled.div`
    color: ${COLORS.grayFont};
    position: absolute;
    bottom: 30px;
    padding-left: 30px;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
`;
