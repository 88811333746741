'use client';
import React from 'react';
import ChangeListingTypeForm from 'features/listings/containers/ChangeListingTypeFormContainer';
import LoginModal from 'features/auth/containers/LoginModalContainer';
import PhotoInputModal from 'features/listings/components/PhotoUploadModal/PhotoUploadModal';
import Prompt from '../Prompt/Prompt';
import UploadReviews from 'features/reviews/containers/UploadReviewsContainer';
import MarkdownModal from 'features/editorials/components/MarkdownModal/MarkdownModal';
import {useSelector} from 'react-redux';

const MODALS = {
    DELETE_USER: Prompt,
    DELETE_ACCOUNT: Prompt,
    LOGIN: LoginModal,
    ATTACH_LISTING: Prompt,
    CHANGE_LISTING_TYPE: ChangeListingTypeForm,
    PHOTO_INPUT: PhotoInputModal,
    DELETE_CARD: Prompt,
    UPLOAD_REVIEWS: UploadReviews,
    MARKDOWN_EXAMPLES: MarkdownModal,
};

const ModalRoot = () => {
    const modal = useSelector((state) => state.modal);
    const {type, props} = modal;
    if (!Object.keys(MODALS).includes(type)) return <span />;

    const ModalToDisplay = MODALS[type];

    return <ModalToDisplay {...props} />;
};

export default ModalRoot;
