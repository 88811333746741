'use client';
import React from 'react';

import Modal from 'features/modal/components/Modal/Modal';
import SidebarHeader from 'features/sidebar/components/SidebarHeader/SidebarHeader';
import SidebarLink from 'features/sidebar/components/SidebarLink/SidebarLink';
import DashboardIcon from 'assets/img/icons/dashboard-icon.svg';
import AccountIcon from 'assets/img/icons/account-icon.svg';
import ListingsIcon from 'assets/img/icons/my-listing-icon.svg';
import ReviewsIcon from 'assets/img/icons/reviews-icon.svg';
import EditorialIcon from 'assets/img/icons/editorials.svg';
import ManageLink from 'features/sidebar/components/ManageLink/ManageLink';
import Authorize from 'features/auth/components/Auth/Auth';
import {get} from 'utils/helpers';
import * as styles from './styles';
import {
    SidebarModalCss,
    SidebarHeaderLink,
    SidebarContainer,
    Primary,
    Secondary,
    SecondaryTitle,
    Feedback,
} from './styles';
import {selectCurrentUser, selectCurrentUserPermissions} from 'features/users/selectors';
import {logoutUser} from 'features/auth/actions';
import {hideModal} from 'features/modal/slice';
import {useRouter} from 'next/navigation';
import {useDispatch, useSelector} from 'react-redux';
import KnowledgeBase from '../KnowledgeBase/KnowledgeBase';

const Sidebar = (props) => {
    const dispatch = useDispatch();
    const accountId = useSelector((state) => state.accounts.currentAccountId);
    const user = useSelector(selectCurrentUser);
    const userPermissions = useSelector(selectCurrentUserPermissions);
    const modalType = useSelector((state) => state.modal.type);
    const userAccountType = useSelector((state) => state.accounts.currentAccountType);

    const router = useRouter();

    const handleLogout = () => {
        dispatch(logoutUser());

        router.push('/login');
    };

    const toggleSidebar = () => {
        if (modalType === 'SIDEBAR') {
            dispatch(hideModal());
        }
    };

    /**
     * Set sidebar active state (used for mobile)
     */
    const sidebarActive = modalType === 'SIDEBAR';
    const userId = user && user.user_id;

    return (
        <Modal
            $css={SidebarModalCss}
            mousedown
            sidebarActive={sidebarActive}
            handleClose={toggleSidebar}>
            <SidebarContainer>
                <SidebarHeaderLink href="/dashboard">ClientReach</SidebarHeaderLink>
                <SidebarHeader user={user} handleLogout={handleLogout} />
                <Primary onClick={() => dispatch(hideModal())}>
                    <SidebarLink
                        text="Dashboard"
                        href="/dashboard"
                        Icon={DashboardIcon}
                    />
                    <Authorize permissions={['account-view-own']}>
                        <SidebarLink
                            text="My Account"
                            href={
                                userAccountType === 'marketing'
                                    ? `/account/${accountId}/subaccounts`
                                    : `/account/${accountId}/listings`
                            }
                            Icon={AccountIcon}
                        />
                    </Authorize>
                    <Authorize
                        authorize={(user) =>
                            user.permissions.includes('review-view-own') ||
                            user.permissions.includes('review-view-all') ||
                            user.permissions.includes('review-view-publishedReviews')
                        }>
                        <SidebarLink
                            text="Reviews"
                            href={
                                userPermissions.includes('review-view-own') &&
                                !userPermissions.includes('review-view-all')
                                    ? `/account/${accountId}/reviews`
                                    : '/reviews'
                            }
                            Icon={ReviewsIcon}
                        />
                    </Authorize>
                </Primary>
                <Secondary onClick={() => dispatch(hideModal())}>
                    <Authorize
                        authorize={({permissions}) =>
                            permissions.includes('account-view-all') ||
                            permissions.includes('user-view-all') ||
                            permissions.includes('role-view-all') ||
                            permissions.includes('listing-view-all')
                        }>
                        <SecondaryTitle>Manage</SecondaryTitle>
                    </Authorize>
                    <Authorize permissions={['account-view-all']}>
                        <ManageLink
                            handleClick={() => dispatch(hideModal())}
                            text="Accounts"
                            href="/accounts"
                        />
                    </Authorize>
                    <Authorize permissions={['listing-view-all']}>
                        <ManageLink text="Find Listings" href="/listings" />
                    </Authorize>
                    <Authorize permissions={['role-view-all']}>
                        <ManageLink text="Permissions + Roles" href="/roles" />
                    </Authorize>
                    <Authorize permissions={['account-view-all']}>
                        <ManageLink
                            handleClick={() => dispatch(hideModal())}
                            text="Reports"
                            href="/reports"
                        />
                    </Authorize>
                    <KnowledgeBase />
                </Secondary>
            </SidebarContainer>
        </Modal>
    );
};

export default Sidebar;
