'use client';
import React from 'react';

import CancelButtonStyled from 'features/form/components/CancelButton/CancelButtonStyled';
import Loading from 'features/generic/components/Loading/Loading';
import {isEmpty} from 'utils/helpers';
import ModalWrapperStyled from '../../../modal/components/ModalWrapperStyled/ModalWrapperStyled';

import {
    Container,
    Header,
    Title,
    Description,
    Required,
    Columns,
    Column,
    RequiredColumn,
    BodyContainer,
    Body,
    Item,
    UploadIcon,
    Info,
    ProgressBackground,
    SizeProgress,
    Size,
    RemoveButton,
    Instruction,
    CsvIconStyled,
    FileName,
    Footer,
    StyledBrowse,
    StyledLoading,
    SubmitButton,
    ToggleInfo,
    Status,
    Progress,
    ProgressText,
    Target,
    TargetLink,
} from './styles';

const uploadColumnsRequired = ['listing_id', 'text', 'author_email', 'author'];

const uploadColumnsOptional = [
    'rating_treatment_effectiveness',
    'rating_accommodations_amenities',
    'rating_meals_nutrition',
];

const UploadReviews = (props) => {
    const fileErrors = Object.keys(props.errors || {});
    function handleMultipleChange(event) {
        props.handleMultipleFiles([...event.target.files]);
    }

    return (
        <ModalWrapperStyled $css={Container}>
            <Header>
                <Title>
                    Upload Reviews
                    <ToggleInfo $showInfo={props.showInfo} onClick={props.toggleInfo}>
                        Info
                    </ToggleInfo>
                </Title>
                <Status
                    $errors={props.errors}
                    $isUploading={props.isUploading}
                    $complete={props.complete}>
                    {props.status}
                </Status>
                {props.showInfo && (
                    <div>
                        <hr />
                        <Description>
                            The header row should include the{' '}
                            <Required>required</Required> fields:
                        </Description>
                        <Columns>
                            {uploadColumnsRequired.map((column, i) => (
                                <RequiredColumn key={column}>{column}</RequiredColumn>
                            ))}
                        </Columns>
                        <Columns>
                            {uploadColumnsOptional.map((column, i) => (
                                <Column key={column}>{column}</Column>
                            ))}
                        </Columns>
                        <hr />
                        <Description>Rating values can be 1-5</Description>
                    </div>
                )}
            </Header>
            <BodyContainer>
                <Body>
                    {!isEmpty(props.files) &&
                        props.files.map((file, index) => {
                            let progress = props.progress + '%';

                            if (props.complete && !fileErrors.includes(file.name)) {
                                progress = '100%';
                            }

                            if (props.complete && fileErrors.includes(file.name)) {
                                progress = 'Failed';
                            }

                            return (
                                <Target key={file.name}>
                                    <Item key={file.name} $index={index}>
                                        <React.Suspense fallback={<Loading />}>
                                            <UploadIcon></UploadIcon>
                                        </React.Suspense>
                                        <Info>
                                            <FileName>
                                                {file.name +
                                                    (props.successCount &&
                                                    props.successCount[file.name]
                                                        ? ' - ' +
                                                          props.successCount[file.name]
                                                        : '')}
                                            </FileName>
                                            {!fileErrors.includes(file.name) && (
                                                <ProgressBackground
                                                    $complete={props.complete}>
                                                    <Progress
                                                        $complete={props.complete}
                                                        $progress={props.progress}
                                                    />
                                                </ProgressBackground>
                                            )}
                                            <SizeProgress>
                                                <Size>
                                                    {Math.ceil(file.size / 100) + 'kb '}
                                                </Size>
                                                <ProgressText
                                                    $complete={props.complete}
                                                    $errors={props.errors}
                                                    $fileName={FileName}>
                                                    {props.errors &&
                                                    props.errors[file.name]
                                                        ? props.errors[file.name]
                                                        : progress}
                                                </ProgressText>
                                            </SizeProgress>
                                        </Info>
                                        <RemoveButton
                                            onClick={() =>
                                                props.handleRemoveFile(file.name)
                                            }>
                                            {!props.isUploading ? 'Remove' : ''}
                                        </RemoveButton>
                                    </Item>
                                </Target>
                            );
                        })}
                    <TargetLink $files={props.files}>
                        <input
                            id="reviews-file-upload"
                            style={{display: 'none'}}
                            type="file"
                            accept=".csv"
                            multiple
                            onChange={handleMultipleChange}
                        />
                        <Instruction>
                            <CsvIconStyled />
                            <StyledBrowse htmlFor="reviews-file-upload">
                                Browse
                            </StyledBrowse>
                            to {props.files?.length ? 'Replace' : ''} Upload
                        </Instruction>
                    </TargetLink>
                </Body>
            </BodyContainer>
            <Footer>
                <CancelButtonStyled handleClick={props.handleClose} />
                {!props.complete && (
                    <SubmitButton $files={props.files} onClick={props.handleUpload}>
                        {props.isUploading ? (
                            <StyledLoading>
                                <Loading />
                            </StyledLoading>
                        ) : (
                            'Submit'
                        )}
                    </SubmitButton>
                )}
                {props.complete && (
                    <SubmitButton $files={props.files} onClick={props.handleClose}>
                        Done
                    </SubmitButton>
                )}
            </Footer>
        </ModalWrapperStyled>
    );
};

export default UploadReviews;
