import {createSelector} from 'reselect';

import {isEmpty} from 'utils/helpers';

const getReviewResults = (state) => state.reviews.results;
const getActiveReviewId = (state) => state.reviews.activeReviewId;

// export const selectIterableReviews = createSelector([getReviewResults], reviews => {
//     if (!reviews) return [];
//     return Object.keys(reviews).map(key => reviews[key]);
// });

export const selectActiveReview = createSelector(
    [getReviewResults, getActiveReviewId],
    (reviews = [], reviewId) => {
        return reviews.find((review) => review.review_id === reviewId);
    },
);

// export const selectActiveIndex = createSelector(
//     [getReviewResults, getActiveReviewId],
//     (reviews, activeId) => {
//         const activeIndex = reviews.findIndex(review => review.review_id === activeId);

//         return activeIndex;
//     }
// );

// export const selectNextIndex = createSelector([selectActiveIndex], activeIndex => {
//     return activeIndex + 1;
// });

// export const selectActiveIndexFromTotal = createSelector(
//     [selectActiveIndex, getTotal, getPerPage, getPage],
//     (activeIndex, total, perpage, page) => {
//         return activeIndex + (page - 1) * perpage;
//     }
// );

// export const selectNextReview = createSelector(
//     [getReviewResults, selectNextIndex],
//     (reviews, nextIndex) => {
//         return reviews[nextIndex];
//     }
// );

export const selectOfficialResponse = createSelector(
    [selectActiveReview],
    (activeReview) => {
        if (isEmpty(activeReview)) return {};

        const officialResponse = activeReview && activeReview.official_response;

        return officialResponse;
    },
);
