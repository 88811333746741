'use client'
import React from 'react';
import {Field} from 'redux-form';

import Loading from 'features/generic/components/Loading/Loading';
import CancelButton from 'features/form/components/CancelButton/CancelButton';
import {get} from 'utils/helpers';
import TextInput from 'features/form/components/TextInput/TextInput';
import ModalWrapper from 'features/modal/components/ModalWrapper/ModalWrapper';
import SubmitButton from 'features/form/components/SubmitButton/SubmitButton';
import TextAreaInput from 'features/form/components/TextAreaInput/TextAreaInput';
import CloseIcon from 'assets/img/icons/close_menu_white.svg';
import WarningIcon from 'assets/img/icons/alert.svg';

import * as styles from './styles';

const ChangeListingTypeForm = (props) => {
    return (
        <ModalWrapper>
            <div style={styles.container}>
                {props.error && <div style={styles.error}>{props.error}</div>}
                <WarningIcon style={styles.warning} />
                {props.includeVipWarning && (
                    <div style={styles.message}>
                        All active VIP State subscriptions will be canceled.
                    </div>
                )}
                {props.includeReason && (
                    <div>
                        <div style={styles.message}>
                            Please provide a <b style={styles.reason}>reason</b> for
                            changing the listing type
                            <TextInput
                                style={styles.input}
                                name="reason"
                                handleChange={props.handleReasonUpdate}
                            />
                        </div>
                    </div>
                )}
                {!props.isSaving && <SubmitButton handleClick={props.handleSubmit} />}
                {props.isSaving && <Loading style={styles.loading} />}
                <CancelButton handleClick={props.handleClose} />
            </div>
        </ModalWrapper>
    );
};

export default ChangeListingTypeForm;
