import {COLORS} from 'core/styles';
import getAvatarId from './getAvatarId';
import styled from 'styled-components';
import {css} from 'styled-components';
import AvatarIcon from 'assets/img/icons/profile-01.svg';

export let PhotoCss = css`
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
`;

export const AvatarIconSvg = styled(AvatarIcon)`
    position: relative;
    top: -8%;
    left: -8%;
    width: 116%;
    height: 116%;
`;

const colorRandomizer = (user) => {
    switch (getAvatarId(user)) {
        case 0:
            return COLORS.warning;
        case 1:
            return COLORS.primary;
        case 2:
            return COLORS.danger;
        default:
            return COLORS.gray;
    }
};

export const AvatarIconStyled = styled.div`
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width + 'px' : '100%')};
    height: ${(props) => (props.height ? props.height + 'px' : '100%')};
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;
    & * {
        fill: ${(props) => colorRandomizer(props.user)};
    }
`;
