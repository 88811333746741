import {COLORS} from 'core/styles';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    display: block;
    padding-left: 12px;
    height: 50px;
    line-height: 0.5;

    &.active * {
        fill: ${COLORS.primary};
        color: ${COLORS.primary};
    }
`;

export const StyledIcon = styled.span`
    vertical-align: middle;
    display: inline-block;
    width: 55px;
`;

export const StyledText = styled.div`
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    font-size: 12px;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    left: -5px;
`;
