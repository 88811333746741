'use client';
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'utils/helpers';
import {refreshToken} from 'features/auth/actions';
import {TOKEN_REFRESH_INTERVAL_MS} from 'core/constants';
import {selectCurrentUserId} from 'features/users/selectors';
import IdleTimer from 'features/idletimer/IdleTimer';
import Sidebar from 'features/sidebar/components/Sidebar/Sidebar';
import ModalRoot from 'features/modal/components/ModalRoot/ModalRoot';
import {ContentContainer, Inner} from 'features/app/components/App/styles';
import NotificationContainer from 'features/notification/containers/NotificationContainer';
import Header from 'features/header/components/Header/Header';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';
import {updateHistory} from 'features/app/slice';
import LoadingShell from 'features/generic/components/LoadingShell/LoadingShell';

let AppContainer = ({children, ...props}) => {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = useSearchParams();

    // const accounts = useSelector((state) => state.accounts);
    // const accountId = accounts.currentAccountId;

    const userId = useSelector(selectCurrentUserId);

    const auth = useSelector((state) => state.auth);
    const isAuthenticated = auth.isAuthenticated;

    const modal = useSelector((state) => state.modal);
    const modalType = modal.type;

    const dispatch = useDispatch();

    /*
     * Keep track of user url history in redux
     * So we don't lose previous page on SSR
     */
    useEffect(() => {
        const url = `${pathname}${
            searchParams.toString() ? `?${searchParams.toString()}` : ''
        }`;
        dispatch(updateHistory(url));
    }, [pathname, searchParams]);

    const [tokenInterval, setTokenInterval] = useState(null);
    const [timeSinceTokenRefresh, setTimeSinceTokenRefresh] = useState(null);

    /*
     * Request refresh token on mount if authenticated
     * Re-request refresh token every 4 mins
     */
    useEffect(() => {
        if (!isAuthenticated || pathname.includes('login')) return;

        props.refreshToken();

        const intervalId = setInterval(() => {
            if (modalType !== 'LOGIN') {
                props.refreshToken();
            }
        }, TOKEN_REFRESH_INTERVAL_MS);

        setTokenInterval(intervalId);

        return () => clearInterval(intervalId);
    }, [isAuthenticated, modalType]);

    /*
     * Handle refresh in cases where user is inactive or switches tabs
     * and the setInterval won't fire in the background
     */
    const handleOnAction = (event) => {
        if (null === timeSinceTokenRefresh) {
            setTimeSinceTokenRefresh(event.timeStamp);
            return;
        }

        const duration = event.timeStamp - timeSinceTokenRefresh;
        if (duration >= TOKEN_REFRESH_INTERVAL_MS) {
            props.refreshToken();
            setTimeSinceTokenRefresh(event.timeStamp);
        }
    };

    if (auth.isLoggingIn) {
        return <LoadingShell />;
    }

    if (!isAuthenticated || !userId) {
        if (
            pathname.includes('login') ||
            pathname.includes('forgot_password') ||
            pathname.includes('password_reset')
        ) {
            return children;
        } else {
            return router.push('/login');
        }
    }

    return (
        <>
            {/* logout user 15 mins idle */}
            <IdleTimer onAction={handleOnAction} />
            <Sidebar />
            <ModalRoot />
            <ContentContainer>
                <Header />
                <NotificationContainer />
                <Inner>{children}</Inner>
            </ContentContainer>
        </>
    );
};

AppContainer = connect(null, {
    refreshToken,
})(AppContainer);

export default withRouter(AppContainer);
