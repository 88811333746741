import React from 'react';
import Link from 'next/link';

import Avatar from 'features/users/components/Avatar/Avatar';

import * as styles from './styles';
import {Names} from './styles';

const Wrapper = (props) => {
    if (props.href) {
        return (
            <Link {...props.style} onClick={props.handleClick} href={props.href}>
                {props.children}
            </Link>
        );
    }

    return (
        <div {...props.style} onClick={props.handleClick}>
            {props.children}
        </div>
    );
};

const UserDescription = (props) => {
    return (
        <Wrapper {...props}>
            {!props.hidePhoto && (
                <Avatar
                    user={props.user}
                    width={props.width || 35}
                    height={props.height || 35}
                />
            )}
            <div style={styles.info}>
                <Names inverted={props.inverted}>
                    {props.user.first_name} {props.user.last_name}
                </Names>
                <div style={styles.role}>{props.user.role_name}</div>
            </div>
            {props.children}
        </Wrapper>
    );
};

export default UserDescription;
