import {Z, bp, COLORS} from 'core/styles';
import styled from 'styled-components';

export let container = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#000',
    zIndex: Z.logoutModal,
    opacity: 0.75,
};

export let ModalContainer = styled.div`
    position: fixed;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    overflow: auto;
    z-index: ${Z.logoutModal};
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export let header = {
    textTransform: 'lowercase',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '1px',
    margin: '0 auto 24px',
    textAlign: 'center',
};

export let iconContainer = {
    width: '40px',
    height: '40px',
};

export let attention = {
    fontWeight: 'bold',
    fontSize: '32px',
};

export let message = {
    fontWeight: 'bold',
    fontSize: '18px',
};

export let button = {
    fontWeight: 'bold',
    fontSize: '18px',
    background: COLORS.primary,
    color: '#fff',
    padding: '12px',
    cursor: 'pointer',
    marginTop: '24px'
};

export let inputContainer = {
    marginBottom: '30px',
};

export let input = {
    margin: '0 auto',
    maxWidth: '385px',
};

export let buttonContainer = {
    textAlign: 'center',
};

export let logout = {
    cursor: 'pointer',
    textTransform: 'uppercase',
    color: COLORS.grayFont,
    fontSize: '11px',
    fontWeight: 'bold',
    display: 'inline-block',
    marginRight: '15px',
};
