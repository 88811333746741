import {COLORS, Z} from 'core/styles';

export let container = {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: Z.modal,
};

export let background = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#000',
    opacity: 0.35,
};

export let aligner = {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    height: '100%',

    '&:before': {
        content: '""',
        display: 'inline-block',
        height: '100%',
        verticalAlign: 'middle',
        marginRight: '-1px',
    },
};

export let outer = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
};

export const wrapper = (props) => {
    let styles = {
        maxWidth: '450px',
        margin: '0 auto',
        textAlign: 'center',
        position: 'relative',
    };

    if (props.style) {
        return styles, props.style;
    }

    return styles;
};

export let header = {
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    padding: '15px 15px 10px',
    backgroundColor: COLORS.gray,
    textAlign: 'right',
    height: '45px',
};

export let close = {
    width: '24px',
    position: 'absolute',
    right: '15px',
    top: '10px',
    cursor: 'pointer',

    '& #close': {
        transform: 'scale(1.5) translate(-4px, -4px)',
    },
};

export let body = {
    padding: '15px 0',
    backgroundColor: '#fff',
};
