'use client';
import {makeStore} from '../lib/store';
import {setupListeners} from '@reduxjs/toolkit/query';
import {useEffect, useRef} from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import LoadingShell from 'features/generic/components/LoadingShell/LoadingShell';

export const StoreProvider = ({children}) => {
    const storeRef = useRef(null);

    if (!storeRef.current) {
        // Create the store instance the first time this renders
        storeRef.current = makeStore();
    }
    const persistor = persistStore(storeRef.current);

    useEffect(() => {
        if (storeRef.current != null) {
            // configure listeners using the provided defaults
            // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
            const unsubscribe = setupListeners(storeRef.current.dispatch);
            return unsubscribe;
        }
    }, []);

    return (
        <Provider store={storeRef.current}>
            <PersistGate loading={<LoadingShell />} persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    );
};
