import {COLORS, bp, lighten, darken} from 'core/styles';
import styled from 'styled-components';

export const Names = styled.div`
    cursor: pointer;
    display: block;
    margin-bottom: 2px;
    max-width: 200px;
    overflow: hidden;
    text-decoration: underline;
    font-weight: bold;
    color: ${(props) => (props.inverted ? darken(COLORS.grayLight, 15) : COLORS.primary)};

    ${bp('md')} {
        max-width: 130px;
    }
`;

export let info = {
    display: 'inline-block',
    verticalAlign: 'middle',
};

export let role = {
    color: COLORS.grayFont,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '9px',
};
