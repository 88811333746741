'use client';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {logoutUser} from 'features/auth/actions';
import LoginModal from 'features/auth/components/LoginModal/LoginModal';
import {useRouter} from 'next/navigation';

let LoginModalContainer = (props) => {
    const router = useRouter();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logoutUser());
        router.push('/login');
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            handleLogout();
        }, 60 * 1000); // 1 minute

        return () => {
            clearTimeout(timeout);
        };
    }, []);
    

    return <LoginModal {...props} handleLogout={handleLogout} />;
};

export default LoginModalContainer;
