'use client';
import React from 'react';
import {hideNotification} from 'features/notification/slice';
import Notification from 'features/notification/components/Notification/Notification';
import {useDispatch, useSelector} from 'react-redux';
import {useRouter} from 'next/navigation';

const NotificationContainer = (props) => {
    const dispatch = useDispatch();
    const router = useRouter();

    const notification = useSelector((state) => state.notification);
    const updateAvailable = notification.showUpdateNotification;
    const success = notification.successNotification;
    const error = notification.errorNotification;

    const handleAction = () => {
        dispatch(hideNotification());

        if (updateAvailable) {
            router.refresh();
        }
    };
    return (
        <Notification
            updateAvailable={updateAvailable}
            success={success}
            error={error}
            handleAction={handleAction}
        />
    );
};

export default NotificationContainer;
