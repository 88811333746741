import React, {useState, useEffect} from 'react';

import {isEmpty} from 'utils/helpers';
import Loading from 'features/generic/components/Loading/Loading';

const AsyncLoad = (props) => {
    const [state, setState] = useState({
        isLoaded: false,
        modules: {},
    });

    useEffect(() => {
        let _isMounted = true;

        setState({isLoaded: false});

        const {modules} = props;
        const keys = Object.keys(props.modules);

        Promise.all(keys.map((key) => modules[key]))
            .then((modulesArray) =>
                modulesArray.reduce((acc, mod, i) => {
                    const key = keys[i];

                    acc[key] = mod.default || mod;

                    return acc;
                }, {}),
            )
            .then((result) => {
                if (!_isMounted) return null;

                setState({modules: result, isLoaded: true});
            });

        return () => {
            _isMounted = false;
        };
    }, []);

    if (!state.isLoaded) return <Loading />;

    return React.Children.only(props.children(state.modules));
};

export default (Component, modules = {}) =>
    (props) => {
        return (
            <AsyncLoad modules={modules}>
                {(mods) => <Component {...props} {...mods} />}
            </AsyncLoad>
        );
    };
