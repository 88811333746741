import {COLORS} from 'core/styles';

export let container = {
    cursor: 'pointer',
    backgroundColor: '#000',
    fontSize: '13px',
    position: 'relative',
    textAlign: 'left',
    height: '82px',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 10px 20px 0',
};

export let user = {
    paddingLeft: '15px',
};

export let logout = {
    textAlign: 'right',
    marginLeft: '3px',
    flex: 1,
};

export let logoutText = {
    display: 'inline-block',
    verticalAlign: 'middle',
    textTransform: 'lowercase',
    color: COLORS.grayFont,
    fontWeight: 500,
    fontSize: '10px',
    opacity: 0.75,
};

export let logoutSvgContainer = {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '30px',
    height: '30px',
    transform: 'rotate(180deg)',
};

export let logoutSvg = {
    width: '100%',
    height: '100%',
};
