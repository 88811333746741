import React from 'react';

import WarningIcon from 'assets/img/icons/alert.svg';
import Modal from 'features/modal/components/Modal/Modal';

import * as styles from './styles';
import {ModalContainer} from './styles';

const LoginModal = (props) => {
    return (
        <div>
            <Modal handleClose={() => null}>
                <ModalContainer>
                    <div style={styles.header}>ClientReach</div>
                    <div style={styles.iconContainer}>
                        <WarningIcon />
                    </div>
                    <div style={styles.attention}>Attention</div>
                    <div style={styles.message}>
                        Your session has timed out. <br />
                        Please login again to refresh your data so you can see recent
                        information.
                    </div>
                    <button style={styles.button} onClick={props.handleLogout}>
                        Log Back In
                    </button>
                </ModalContainer>
            </Modal>
        </div>
    );
};

export default LoginModal;
