'use client'
import React from 'react';

import ModalWrapper from 'features/modal/components/ModalWrapper/ModalWrapper';

import {
    Code,
    Container,
    Example,
    OrderedList,
    Quote,
    Section,
    SectionFixed,
    Subheader,
    UnorderedList,
} from './styles';

const MarkdownModal = (props) => {
    return (
        <ModalWrapper>
            <Container>
                <h5>Markdown Examples</h5>
                <Subheader>Headers</Subheader>
                <SectionFixed>
                    <Example>
                        <h2>Header 1</h2>
                    </Example>
                    <Code># Header 1</Code>
                </SectionFixed>
                <SectionFixed>
                    <Example>
                        <h3>Header 2</h3>
                    </Example>
                    <Code>## Header 2</Code>
                </SectionFixed>
                <SectionFixed>
                    <Example>
                        <h4>Header 3</h4>
                    </Example>
                    <Code>### Header 3</Code>
                </SectionFixed>
                <Subheader>Font Styling</Subheader>
                <SectionFixed>
                    <Example>
                        <p>
                            <em>italicized text</em>
                        </p>
                    </Example>
                    <Code>_italicized text_</Code>
                </SectionFixed>
                <SectionFixed>
                    <Example>
                        <p>
                            <b>bolded text</b>
                        </p>
                    </Example>
                    <Code>**bolded text**</Code>
                </SectionFixed>
                <Subheader>Blockquote</Subheader>
                <SectionFixed>
                    <Example>
                        <Quote>Happily ever after...</Quote>
                    </Example>
                    <Code>&gt; Happily ever after...</Code>
                </SectionFixed>
                <Subheader>Lists</Subheader>
                <Section>
                    <Example>
                        <UnorderedList>
                            <li>Willingness to learn</li>
                            <li>Good attitude</li>
                            <li>Open mind</li>
                        </UnorderedList>
                    </Example>
                    <Code>
                        * Willingness to learn
                        <br />
                        * Good attitude
                        <br />
                        * Open mind
                        <br />
                    </Code>
                </Section>
                <Section>
                    <Example>
                        <OrderedList>
                            <li>Establish Credibility</li>
                            <li>Present Main Points</li>
                            <li>Restate Thesis</li>
                        </OrderedList>
                    </Example>
                    <Code>
                        1. Establish Credibility
                        <br />
                        2. Present Main Points
                        <br />
                        3. Restate Thesis
                        <br />
                    </Code>
                </Section>
                <Subheader>Links</Subheader>
                <SectionFixed>
                    <Example>
                        <a href="https://rehabs.com">My link</a>
                    </Example>
                    <Code>[My link](rehabs.com)</Code>
                </SectionFixed>
                <Subheader>Divider</Subheader>
                <Section>
                    <Example>
                        <p>The end of my first topic.</p>
                        <hr />
                        <p>The start of a new topic.</p>
                    </Example>
                    <Code>
                        The end of my first topic.
                        <br />
                        ---
                        <br />
                        The start of a new topic.
                    </Code>
                </Section>
                <Subheader>Footnotes</Subheader>
                <Section>
                    <Example>
                        <p>
                            This is a plain text footnote.
                            <a href="footnote-1">
                                <sup> [1] </sup>
                            </a>
                            We will link to Rehabs.com in our second footnote.
                            <a href="footnote-2">
                                <sup> [2]</sup>
                            </a>
                        </p>
                        <br />
                        <p>
                            <small className="footnote" id="footnote-1">
                                <a href="#footnote-1">
                                    <sup>[1] </sup>
                                </a>
                                Plain text
                            </small>
                            <br />
                            <small className="footnote" id="footnote-2">
                                <a href="#footnote-2">
                                    <sup>[2] </sup>
                                </a>
                                <a href="rehabs.com">Linked</a>
                            </small>
                        </p>
                    </Example>
                    <Code>
                        This is a plain text footnote. [^1] We will link to Rehabs.com in
                        our second footnote. [^2]
                        <br />
                        <br />
                        [^1]: Plain text
                        <br />
                        [^2]: [Linked](rehabs.com)
                    </Code>
                </Section>
            </Container>
        </ModalWrapper>
    );
};

export default MarkdownModal;
