/**
 * Derive 1, 2, or 3 from user id to generate avatar
 */
export default (user) => {
    const highestPossible = 244;
    const lowestPossible = 96;
    const id = user.user_id.slice(-2);
    const [a, b] = id.split('').map((x) => x.charCodeAt(0));

    const value = a + b - 96;

    if (value >= 0 && value <= 49) {
        return 0;
    } else if (value > 49 && value <= 99) {
        return 1;
    }

    return 2;
};
