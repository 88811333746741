'use client';
import React from 'react';

import {HeaderContainer, ButtonContainer, MenuButton, CloseButton} from './styles';
import {showModal, hideModal, toggleModal} from 'features/modal/slice';
import {useDispatch, useSelector} from 'react-redux';

const Header = () => {
    const dispatch = useDispatch();
    const modal = useSelector((state) => state.modal);
    const {type, props} = modal;

    const handleToggleClick = () => {
        dispatch(
            toggleModal({
                type: 'SIDEBAR',
            }),
        );
    };

    return (
        <HeaderContainer>
            <ButtonContainer onClick={handleToggleClick}>
                {type === 'SIDEBAR' ? <CloseButton /> : <MenuButton />}
            </ButtonContainer>
        </HeaderContainer>
    );
};

export default Header;
