import {bp} from 'core/styles';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    ${(props) => (props.$css ? props.$css : '')}
`;

export const TextAreaStyled = styled.textarea`
    line-height: 2;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    margin-right: 20px;
    margin-bottom: 10px;
    border-style: none;
    width: 100%;
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.1),
        inset -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-width: 1px;
    min-height: 175px;
    ${bp('md')} {
        border: none;
        box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.1),
            inset -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
    &[disabled] {
        opacity: 0.5;
    }
    ${(props) => (props.$css ? props.$css : '')}
`;
