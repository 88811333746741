import React from 'react';
import {StyledLink, StyledIcon, StyledText} from './styles';
import {usePathname} from 'next/navigation';

const SidebarLink = ({text, Icon, className, href}) => {
    const pathname = usePathname();

    return (
        <StyledLink className={`${pathname === href ? 'active' : ''}`} href={href}>
            <StyledIcon>
                <Icon />
            </StyledIcon>
            <StyledText>{text}</StyledText>
        </StyledLink>
    );
};

export default SidebarLink;
