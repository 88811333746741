import React from 'react';

import * as styles from './styles';

const CancelButton = (props) => {
    return (
        <div style={styles.container(props)} onClick={props.handleClick}>
            {props.children || 'Cancel'}
        </div>
    );
};

export default CancelButton;
