import React from 'react';
import {StyledLink, Text, StyledArrow, StyledSVG} from './styles';

import ArrowIcon from 'assets/img/icons/arrow_right.svg';
import {usePathname} from 'next/navigation';

const ManageLink = (props) => {
    const pathname = usePathname();

    return (
        <StyledLink
            className={`${pathname === props.href ? 'active' : ''}`}
            href={props.href}
            onClick={props.handleClick}>
            <Text>{props.text}</Text>
            <StyledArrow>
                <StyledSVG>
                    <ArrowIcon />
                </StyledSVG>
            </StyledArrow>
        </StyledLink>
    );
};

export default ManageLink;
