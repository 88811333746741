import React from 'react';

import {AvatarIconStyled, AvatarIconSvg, PhotoCss} from './styles';
import PhotoStyled from '../../../generic/components/PhotoStyled/PhotoStyled';

const Avatar = (props) => {
    if (props.user && props.user.avatar) {
        return (
            <PhotoStyled
                $css={PhotoCss}
                src={props.user.avatar}
                width={props.width}
                height={props.height}
            />
        );
    }

    return (
        <AvatarIconStyled
            width={props.width ? props.width : 50}
            height={props.height ? props.height : 50}
            user={props.user}
            onClick={props.handleClick}>
            <AvatarIconSvg />
        </AvatarIconStyled>
    );
};

export default Avatar;
