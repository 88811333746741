export const SUPER_ADMIN_NAME = 'Super Admin';
export const TIMEOUT_NORMAL_MINUTES = 15; // 15 minutes
export const TIMEOUT_SUPER_ADMIN_MINUTES = 120; // 2 hours
export const MICROSECONDS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;

export const isSuperAdmin = (user) => {
    return user?.role_name === SUPER_ADMIN_NAME;
};

export const getIdleTimeoutMicroseconds = (user) => {
    if (isSuperAdmin(user)) {
        return TIMEOUT_SUPER_ADMIN_MINUTES * SECONDS_PER_MINUTE * MICROSECONDS_PER_SECOND;
    }
    return TIMEOUT_NORMAL_MINUTES * SECONDS_PER_MINUTE * MICROSECONDS_PER_SECOND;
};
